'use client';
import { useEffect, useState } from 'react';

const BrowserCheck = () => {
  const [isOldBrowser, setIsOldBrowser] = useState(false);

  useEffect(() => {
    const isStickySupported = CSS.supports('position', 'sticky');
    const isFlexSupported = CSS.supports('display', 'flex');

    if (!isStickySupported || !isFlexSupported) {
      setIsOldBrowser(true);
    }
  }, []);

  if (!isOldBrowser) {
    return null;
  }

  return (
    <div className="fixed left-0 right-0 top-0 z-50 bg-red-500 p-4 text-white">
      <p>
        Your browser is outdated and may not display all features of this website correctly. Please
        consider updating your browser for a better experience.
      </p>
    </div>
  );
};

export default BrowserCheck;
